import React from 'react';

import Link from 'utils/localized-link';

import Banner from 'components/sections/banner';
import Benefits from 'components/sections/benefits';
import Layout from 'components/layout';
import PartnersFaq from 'components/sections/partners-faq';
import PartnersHeader from 'components/sections/partners-header';
import PartnersRegisterSteps from 'components/sections/partners-register-steps';
import PartnersStart from 'components/sections/partners-start';
import PartnersTestimonials from 'components/sections/partners-testimonials';
import SEO from 'components/seo';

import CreditCardIcon from 'images/icons/credit-card.inline.svg';
import PeopleIcon from 'images/icons/people.inline.svg';
import InfrastructureIcon from 'images/icons/infrastructure.inline.svg';


const WEB_APP_URL = process.env.GATSBY_WEB_APP_URL;

const BENEFITS = [
  {
    title: 'partners.benefits.b1.title',
    description: 'partners.benefits.b1.description',
    icon: CreditCardIcon
  },
  {
    title: 'partners.benefits.b2.title',
    description: 'partners.benefits.b2.description',
    icon: PeopleIcon
  },
  {
    title: 'partners.benefits.b3.title',
    description: 'partners.benefits.b3.description',
    icon: InfrastructureIcon
  }
];

const bannerButtons = [
  {
    external: true,
    //linkComponent: Link,
    linkProps: {
      //to: `${WEB_APP_URL}/register`
      to: '#register'
    },
    noNewTab: true,
    light: true,
    noArrow: true,
    small: true,
    text: 'partners.banner.buttons.sign_up'
  },
  {
    linkComponent: Link,
    linkProps: {
      to: '/contacts'
    },
    light: true,
    noArrow: true,
    noBackground: true,
    small: true,
    text: 'partners.banner.buttons.contacts'
  }
];

const bannerImage = 'create-account.png';

const PartnersPage = ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <SEO title="partners.title" />
    <PartnersHeader />
    <Benefits
      benefits={BENEFITS}
      title="partners.benefits.title"
    />
    <PartnersStart />
    <PartnersRegisterSteps />
    <PartnersTestimonials />
    <PartnersFaq />
    <Banner
      buttons={bannerButtons}
      image={bannerImage}
      text="partners.banner.text"
      title="partners.banner.title"
    />
  </Layout>
);

export default PartnersPage;
