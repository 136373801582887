import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import Cookies from 'universal-cookie';
import Cookies from './../../../../node_modules/universal-cookie';

import { useIntl } from 'react-intl';

import AuthenticationApi from '@youship/api/authentication';

import Button from '@youship/components/objects/button';

import ConfirmationCode from '@youship/components/objects/confirmation-code';

const cookies = new Cookies();

const COOKIES_DOMAIN = process.env.GATSBY_COOKIES_DOMAIN;

const CONFIRMATION_CODE_LENGTH = 4;

const ConfirmationCodeStep = ({ classNames, formClassName, onError, onSubmitting, onSuccess, successMessage }) => {
  const [confirmationCode, setConfirmationCode] = useState(null);
  const [failed, setFailed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFormSubmit = (event) => {
    event.preventDefault();

    setIsSubmitting(true);
    onSubmitting();

    AuthenticationApi.activatePhone({
      activationcode: confirmationCode
    })
      .then((response) => {
        setIsSubmitting(false);
        if (response) {
          cookies.set('USER_REQUIRES_PHONE_ACTIVATION', 'false', { path: '/', domain: COOKIES_DOMAIN });
        }

        onSuccess();

        return response;
      })
      .catch((error) => {
        setFailed(true);
        setIsSubmitting(false);
        setErrorMessage(error?.message || 'Sorry, we were unable to process your request at this time. Please try again later.');
        onError();
      });
  };

  const handleConfirmationCodeChange = (codeCharacters) => {
    const code = codeCharacters.filter(character => !!character);

    setConfirmationCode(code.join(''));
  };

  const isCodeValid = confirmationCode && confirmationCode.length === CONFIRMATION_CODE_LENGTH;
  const isButtonDisabled = !isCodeValid || isSubmitting;

  const intl = useIntl();

  return (
    <form
      className={`${formClassName}__step${classNames ? ` ${classNames}` : ''}`}
      onSubmit={event => handleFormSubmit(event)}
    >
      <div className={`${formClassName}__step-description`}>
        {successMessage}
      </div>
      <div className={`${formClassName}__input-wrapper`}>
        <ConfirmationCode
          label={ intl.formatMessage({ id: 'partners.form.confirmation_code.label' }) }
          length={CONFIRMATION_CODE_LENGTH}
          onChange={handleConfirmationCodeChange}
        />
      </div>
      <Button
        block
        context="primary"
        disabled={isButtonDisabled}
        smallVerticalPadding
        text={ intl.formatMessage({ id: 'partners.form.confirmation_code.label' }) }
        type="submit"
      />
      {failed && (
        <div className={`${formClassName}__error`}>
          {errorMessage}
        </div>
      )}
    </form>
  );
};

ConfirmationCodeStep.propTypes = {
  classNames: PropTypes.string,
  formClassName: PropTypes.string,
  onError: PropTypes.func,
  onSubmitting: PropTypes.func,
  onSuccess: PropTypes.func
};

ConfirmationCodeStep.defaultProps = {
  classNames: null,
  formClassName: 'carrier-registration-form',
  onError: () => { },
  onSubmitting: () => { },
  onSuccess: () => { }
};

export default ConfirmationCodeStep;
