import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import { FormattedMessage } from 'react-intl';

import QuoteIcon from 'images/icons/quote.inline.svg';

import 'swiper/swiper.scss';
import './styles.scss';

SwiperCore.use([Autoplay, EffectFade]);

const TestimonialsSlider = ({ classNames, slides }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [slider, setSlider] = useState(null);

  const handleSlideChange = (swiperEl) => {
    setCurrentStep(swiperEl.activeIndex);
  };

  const handleNavigationClick = (index) => {
    slideTo(index);
  };

  const handleNavigationKeyDown = (event, index) => {
    if (event.keyCode === 13 && currentStep !== index) {
      slideTo(index);
    }
  };

  const slideTo = (index) => {
    if (slider) {
      slider.slideTo(index);
      setCurrentStep(index);
    }
  };

  return (
    <div className={`testimonials-slider__wrapper${classNames ? ` ${classNames}` : ''}`}>
      {Array.isArray(slides) && (
        <Swiper
          autoplay={{ delay: 4000 }}
          className="testimonials-slider"
          effect="fade"
          grabCursor
          slidesPerView="1"
          spaceBetween={24}
          onInit={swiper => setSlider(swiper)}
          onSlideChange={handleSlideChange}
        >
          {slides.map((slide, index) => (
            <SwiperSlide
              key={index}
              className="testimonials-slider__slide"
            >
              <QuoteIcon
                className="testimonials-slider__icon"
              />
              <div className="testimonials-slider__slide-text">
                <FormattedMessage id={slide.text} />
              </div>
              <img
                alt="Slide"
                className="testimonials-slider__slide-image"
                src={slide.image}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <div className="testimonials-slider__navigation">
        {Array.isArray(slides) && slides.length > 1 && slides.map((step, index) => (
          <div
            key={index}
            aria-label={`Navigate to step ${index}`}
            className={`testimonials-slider__navigation-item${index === currentStep ? ' testimonials-slider__navigation-item--is-active' : ''}`}
            role="button"
            tabIndex={0}
            onClick={() => handleNavigationClick(index)}
            onKeyDown={event => handleNavigationKeyDown(event, index)}
          />
        ))}
      </div>
    </div>
  );
};

TestimonialsSlider.propTypes = {
  classNames: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    image: PropTypes.string
  })).isRequired
};

TestimonialsSlider.defaultProps = {
  classNames: null
};

export default TestimonialsSlider;
